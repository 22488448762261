$(document).on 'turbolinks:load', ->
  
  $('#check_all').on 'click', ->
    # console.log($("input[type=checkbox]"))
    # $("input[type=checkbox]").prop('checked', $(this).prop('checked'));
    # $('#date_checkboxes :checkbox').each ->
    $("#date_checkboxes input[type=checkbox]").each ->
      console.log($(this).attr("id"))
      $(this).prop('checked', true);
    return

  $("#event_abmelden").on "change", ->
    # abmelden = ($("#event_abmelden").is(':checked'))
    # konserve = ($("#event_konserve").is(':checked'))
    # if abmelden == true or konserve == true
    #   $("#booking_fields").hide();
    # else
    #   $("#booking_fields").show();
    hide_or_show_booking_fields()


  $("#event_konserve").on "change", ->
    # abmelden = ($("#event_abmelden").is(':checked'))
    # konserve = ($("#event_konserve").is(':checked'))
    # if abmelden == true or konserve == true
    #   $("#booking_fields").hide();
    # else
    #   $("#booking_fields").show();
    hide_or_show_booking_fields()

  hide_or_show_booking_fields = () ->
    # abmelden = ($("#event_abmelden").is(':checked'))
    konserve = ($("#event_konserve").is(':checked'))
    if konserve == true
      $("#booking_fields").hide();
      $("#interior_fields").hide();
    else
      $("#booking_fields").show();
      $("#interior_fields").show();

  #initial
  hide_or_show_booking_fields()